<template>
  <view-item-setting>
    <template #operation>
      <button-ajax :method="operation.save.click">保存</button-ajax>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-tabs v-model="tabs.active" v-loading="table.loading" tab-position="left">
          <en-tab-pane v-for="item of table.attributeData" :key="item.code" :label="item.message" :name="item.code">
            <en-scrollbar :height="height">
              <template v-if="item.code === 'P'">
                <en-tabs>
                  <en-tab-pane label="巴斯夫供应商">
                    <en-form label-position="left" label-width="180">
                      <en-form-item label="巴斯夫供应商">
                        <select-maintain
                          :model-value="Number(find(item.items, ['id.code', 'SRPTSPR'])?.value)"
                          :ajax="{
                            action: 'GET /enocloud/common/customer',
                            params: (params, value) => (params.payload = { name: value, type: 'B' })
                          }"
                          :props="{ label: 'name', value: 'id' }"
                          remote
                          class="w-60"
                          @change="(value: number) => (find(item.items, ['id.code', 'SRPTSPR'])!.value = value.toString())"
                        ></select-maintain>
                      </en-form-item>
                      <en-form-item label="订单类型是否必填">
                        <select-maintain
                          :model-value="find(item.items, ['id.code', 'SRPTORSYE'])?.value"
                          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                          :props="{ label: 'message', value: 'code' }"
                          @change="(value: string) => (find(item.items, ['id.code', 'SRPTORSYE'])!.value = value)"
                          class="w-60"
                        ></select-maintain>
                      </en-form-item>
                      <en-form-item label="是否同一品牌">
                        <select-maintain
                          :model-value="find(item.items, ['id.code', 'REQSAMBRD'])?.value"
                          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                          :props="{ label: 'message', value: 'code' }"
                          @change="(value: string) => (find(item.items, ['id.code', 'REQSAMBRD'])!.value = value)"
                          class="w-60"
                        ></select-maintain>
                      </en-form-item>
                      <en-form-item label="是否供应商确认">
                        <select-maintain
                          :model-value="find(item.items, ['id.code', 'BFSPSRCM'])?.value"
                          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                          :props="{ label: 'message', value: 'code' }"
                          @change="(value: string) => (find(item.items, ['id.code', 'BFSPSRCM'])!.value = value)"
                          class="w-60"
                        ></select-maintain>
                      </en-form-item>
                    </en-form>

                    <div class="text-red">
                      说明：当ENOCH创建采购订单（来源含采购订单、补货单）时，系统检测采购订单中的供应商是否是以上设置的喷涂供应商，如果是系统会自动创建巴斯夫订单，并且将巴斯夫订单发送给巴斯夫SAP系统（中间件是Elemica）。
                    </div>
                  </en-tab-pane>
                  <en-tab-pane label="以诺行水性供应商">
                    <en-form label-position="left" label-width="180">
                      <en-form-item label="以诺行水性供应商">
                        <select-maintain
                          :model-value="Number(find(item.items, ['id.code', 'EWSRPTSPR'])?.value)"
                          :ajax="{
                            action: 'GET /enocloud/common/customer',
                            params: (params, value) => (params.payload = { name: value, type: 'B' })
                          }"
                          :props="{ label: 'name', value: 'id' }"
                          remote
                          @change="(value: number) => (find(item.items, ['id.code', 'EWSRPTSPR'])!.value = value.toString())"
                          class="w-60"
                        ></select-maintain>
                      </en-form-item>
                      <en-form-item label="订单类型是否必填">
                        <select-maintain
                          :model-value="find(item.items, ['id.code', 'EWSRPTORSYE'])?.value"
                          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                          :props="{ label: 'message', value: 'code' }"
                          @change="(value: string) => (find(item.items, ['id.code', 'EWSRPTORSYE'])!.value = value)"
                          class="w-60"
                        ></select-maintain>
                      </en-form-item>
                      <en-form-item label="是否同一品牌">
                        <select-maintain
                          :model-value="find(item.items, ['id.code', 'EWREQSAMBRD'])?.value"
                          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                          :props="{ label: 'message', value: 'code' }"
                          @change="(value: string) => (find(item.items, ['id.code', 'EWREQSAMBRD'])!.value = value)"
                          class="w-60"
                        ></select-maintain>
                      </en-form-item>
                      <en-form-item label="是否供应商确认">
                        <select-maintain
                          :model-value="find(item.items, ['id.code', 'EWSPSRCM'])?.value"
                          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                          :props="{ label: 'message', value: 'code' }"
                          @change="(value: string) => (find(item.items, ['id.code', 'EWSPSRCM'])!.value = value)"
                          class="w-60"
                        ></select-maintain>
                      </en-form-item>
                    </en-form>
                    <div class="text-red">
                      说明：当ENOCH创建采购订单（来源含采购订单、补货单）时，系统检测采购订单中的供应商是否是以上设置的喷涂供应商，如果是系统会自动创建巴斯夫订单，并且将巴斯夫订单发送给巴斯夫SAP系统（中间件是Elemica）。
                    </div>
                  </en-tab-pane>
                </en-tabs>
              </template>

              <template v-else-if="item.code === 'CSTLYAT'">
                <en-form label-position="left" label-width="100">
                  <en-form-item label="活跃期客户">
                    <div class="w-150 grid grid-cols-3 gap-6">
                      <span class="justify-self-end">未到店天数不可超过</span>
                      <en-input-number
                        :model-value="Number(find(item.items, ['id.code', 'ACTCSTDPTD'])?.value)"
                        class="w-full"
                        @change="(value: number) => (find(item.items, ['id.code', 'ACTCSTDPTD'])!.value = value.toString())"
                      ></en-input-number>
                      <span>天</span>
                      <span class="justify-self-end">到店次数</span>
                      <en-input-number
                        :model-value="Number(find(item.items, ['id.code', 'ACTCSTARVC'])?.value)"
                        class="w-full"
                        @change="(value: number) => (find(item.items, ['id.code', 'ACTCSTARVC'])!.value = value.toString())"
                      ></en-input-number>
                      <span>次及以上</span>
                      <span class="justify-self-end">累计消费金额</span>
                      <en-input-number
                        :model-value="Number(find(item.items, ['id.code', 'ACTCSTCSA'])?.value)"
                        class="w-full"
                        @change="(value: number) => (find(item.items, ['id.code', 'ACTCSTCSA'])!.value = value.toString())"
                      ></en-input-number>
                      <span>元及以上</span>
                    </div>
                  </en-form-item>
                  <div class="text-red py-5">说明：活跃期客户设置需要同时满足未到店不可超出多少天数、到店次数、消费金额三个条件</div>
                  <en-form-item label="流失期客户">
                    <div class="w-150 grid grid-cols-3 gap-6">
                      <span class="justify-self-end">未到店天数超出</span>
                      <en-input-number
                        :model-value="Number(find(item.items, ['id.code', 'LSCSTDPTD'])?.value)"
                        class="w-full"
                        @change="(value: number) => (find(item.items, ['id.code', 'LSCSTDPTD'])!.value = value.toString())"
                      ></en-input-number>
                      <span>天</span>
                    </div>
                  </en-form-item>

                  <en-form-item label="摇摆期客户">所有客户-活跃-流失-无消费</en-form-item>
                  <en-form-item label="无消费客户">没有创建过维修工单的客户</en-form-item>
                </en-form>
              </template>

              <en-form v-else class="grid grid-cols-3 gap-x-6">
                <template v-for="formItem of item.items">
                  <en-form-item :label="formItem.id?.message">
                    <template v-if="formItem.id?.valueType === 'STRING'">
                      <en-input
                        v-model="formItem.value"
                        :disabled="formItem.id.code === 'FLSEPATH'"
                        :placeholder="formItem.id.code === 'FLSEPATH' ? '暂不支持' : '请输入'"
                      ></en-input>
                    </template>
                    <template v-else-if="formItem.id?.valueType === 'TEXT'">
                      <en-input v-model="formItem.value" type="textarea"></en-input>
                    </template>
                    <template v-else-if="formItem.id?.valueType === 'PICTURE'">
                      <upload-maintain v-model="formItem.value"></upload-maintain>
                    </template>
                    <template v-else-if="formItem.id?.valueType === 'NUMBER'">
                      <en-input v-model="formItem.value"></en-input>
                    </template>
                    <template v-else-if="formItem.id?.valueType === 'DATE'">
                      <en-date-picker v-model="formItem.value"></en-date-picker>
                    </template>
                    <template v-else-if="formItem.id?.valueType === 'TIME'">
                      <en-date-picker v-model="formItem.value"></en-date-picker>
                    </template>
                    <template v-else-if="formItem.id?.valueType === 'DATETIME'">
                      <en-date-picker v-model="formItem.value"></en-date-picker>
                    </template>
                    <template v-else-if="formItem.id?.valueType === 'REGION'">
                      <cascader-maintain
                        :model-value="formItem.value?.split('/').map(Number)"
                        lazy
                        :ajax="[
                          {
                            action: 'GET /enocloud/common/area/children'
                          },
                          {
                            action: 'GET /enocloud/common/area/children',
                            params(params, value) {
                              params.payload = { parentCode: value[0] }
                            }
                          },
                          {
                            action: 'GET /enocloud/common/area/children',
                            params(params, value) {
                              params.payload = { parentCode: value[1] }
                            }
                          }
                        ]"
                        class="w-full"
                        :props="{ label: 'name', value: 'code', leaf: (option: EnocloudCommonDefinitions['AreaDto']) => !option.hasChildren }"
                        @change="(value: number[]) => (formItem.value = value.join('/'))"
                      ></cascader-maintain>
                    </template>
                    <template v-else-if="formItem.id?.valueType === 'FLAG'">
                      <select-maintain
                        v-model="formItem.value"
                        :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                        :props="{ label: 'message', value: 'code' }"
                        class="w-full"
                        @change="table.value.change(formItem)"
                      ></select-maintain>
                    </template>
                    <template v-else-if="formItem.id?.valueType === 'CUSTOMER'">
                      <select-maintain
                        v-model="formItem.value"
                        :ajax="{ action: 'GET /enocloud/common/customer', params: (params, value) => (params.payload = { name: value, type: 'B' }) }"
                        :props="{ label: 'name', value: '' }"
                        value-key="id"
                        remote
                        class="w-full"
                      ></select-maintain>
                    </template>
                    <template v-else-if="formItem.id?.valueType === 'BRANCH'">
                      <select-maintain
                        v-model="formItem.value"
                        :ajax="{
                          action: 'GET /enocloud/admin/tenant/:tenantId/branch',
                          params: (params) => (params.paths = [store.userTenantId])
                        }"
                        :props="{ label: 'shortName', value: 'id' }"
                        class="w-full"
                        @change="table.value.change(formItem)"
                      ></select-maintain>
                    </template>
                    <template v-else>
                      <select-maintain
                        v-model="formItem.value"
                        :ajax="{
                          action: 'GET /enocloud/common/lookup/:lookupType',
                          params: (params) => (params.paths = table.lookup.paths(formItem))
                        }"
                        :props="{ label: 'message', value: 'code' }"
                        class="w-full"
                      ></select-maintain>
                    </template>

                    <template v-if="formItem.id?.description">
                      <span class="text-xs text-primary">{{ formItem.id?.description }}</span>
                    </template>
                  </en-form-item>
                </template>
              </en-form>
            </en-scrollbar>
          </en-tab-pane>
        </en-tabs>
      </template>
    </flex-box>
  </view-item-setting>
</template>

<script lang="ts">
import { find, uniqBy } from 'lodash-es'

type SystemAttributeDto = EnocloudAdminDefinitions['SystemAttributeDto']

type AttributeData = EnocloudAdminDefinitions['LookupDto'] & {
  items: SystemAttributeDto[]
}

export default factory({
  config: {
    children: {
      operation: {
        save: {
          async click() {
            await this.table.submit()
            return this.table.get()
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/admin/branch/attribute',
            data: 'array',
            loading: true
          },
          submit: {
            action: 'PUT /enocloud/admin/branch/attribute',
            loading: true,
            params(params) {
              params.data = this.table.data
            }
          }
        },
        computed: {
          attributeData() {
            return uniqBy(this.table.data, (item) => item.id?.keyType?.code).map((item) => {
              return {
                ...item.id?.keyType!,
                items: this.table.data.filter((d) => d.id?.keyType?.code === item.id?.keyType?.code)
              } as AttributeData
            })
          }
        },
        children: {
          lookup: {
            paths(formItem: SystemAttributeDto) {
              switch (formItem.id?.code) {
                case 'PTSVTPLT':
                  return ['PTSVTPLT']
                case 'PTSVASNT':
                  return ['PTSVASTPLT']
                case 'ISRSTLTMP':
                  return ['ISTMPTYPE']
                case 'PTPUCTPLT':
                case 'PTSALTPLT':
                  return [formItem.id?.code]
                default:
                  return [formItem.id?.valueType]
              }
            }
          },
          value: {
            change(item: SystemAttributeDto) {
              switch (item.id?.code) {
                case 'CHAINMNG':
                  find(find(this.table.attributeData, ['code', item.id.keyType?.code])!.items, ['id.code', 'PABBRCH'])!.value = ''
                  break
                case 'PABBRCH':
                  find(find(this.table.attributeData, ['code', item.id.keyType?.code])!.items, ['id.code', 'CHAINMNG'])!.value = item.value
                    ? 'Y'
                    : 'N'
                  break
              }
            }
          }
        }
      }
    }
  },

  methods: { find },

  async mounted() {
    await this.table.get()
    this.tabs.active = this.table.attributeData[0].code!
   
  }
})
</script>
